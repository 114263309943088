.Container {
  flex-direction: column;
  padding: var(--offsetXL);

  background-color: var(--color2);
}

.Navigation {
  display: flex;
  flex-wrap: wrap;
  gap: var(--offsetXL);
  align-items: center;
  justify-content: center;
}

.PackageLink {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: calc(100% - var(--offsetL));
  padding: var(--offsetL);

  text-decoration: none;

  background-color: var(--color1);
  border-radius: var(--borderRadius);
  box-shadow: 0 0 4px -2px var(--color5);

  transition: all var(--transitionNormal) ease-in-out;

  &:hover {
    box-shadow: 0 0 7px -1px var(--color5);
  }

  .PackageName {
    color: var(--color4);
  }
}
